@import url(https://fonts.googleapis.com/css?family=Quicksand:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input:focus, input:hover, btn:hover, btn:active{
  outline: none;
  box-shadow: none !important;
}
  a, a:hover{
    text-decoration:none;
    color: #000;
  }

  input:focus, input:hover, button:hover, button:active{
    outline: none;
    box-shadow: none !important;
  }  
  
  .container{ width: 750px !important;}
  .thumbnail{ width: 750px !important;}
  
  .setBody{
    min-height:  110vh;
    position: relative;
  }
  


  .setNavbar{
    position: fixed;
    width: 100%;
  }

  .cardImage{
    background-color: none;
  }
  
  .cardBorderRadius{
    border-radius: 12px;
  }

  .cardImage:hover{
    opacity: 0.9;
  }

  .text-sm {
    font-size: 12px;
  }

  .userIcon{
    width: 20px;
    color: "red";
  }

  .btngradasi{
    background-color: #a4508b; background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 74%); 
    color: #f5f5db;
    text-align: center;
    font-style: italic;
    font-weight: bold;
    border-radius: 10px;
  }

  .btnblue{
    background-color: #0069D9; 
    color: #f5f5db;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    outline: none;
    box-shadow: none !important;
  }

  .borderRadius{
    border-radius: 200px;
    border-color: purple;
    border-style: solid;
    border-width: thin;
  }

  .header-menu-show{
    display: block;
  }

  .search-header{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 7px;
  }

 
  .setBg{
    max-width: 85%;
    min-height: 25%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .txtcarousel{
    font-weight: bold;
  }

  .setCard{
    border-radius: 15px;
  }

  .setFont{
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-60%, -300%);
          transform: translate(-60%, -300%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  }

  .setBoder{
    color: red;
    background-color: red;
  }

  .o1 {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    border: 10px solid #FFC947;
    margin-left: 0px;
    margin-right: -50px;
    display: inline-block;
    -webkit-animation: thecodes1 1.8s infinite alternate;
            animation: thecodes1 1.8s infinite alternate;
  }
  .o2 {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    border: 10px solid #0A1931;
    margin: 0 auto;
    display: inline-block;
    -webkit-animation: thecodes2 1s infinite alternate;
            animation: thecodes2 1s infinite alternate;
  }
  .o3 {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    border: 10px solid #0A1931;
    margin-left:-50px;
    display: inline-block;
    -webkit-animation: thecodes3 3s infinite alternate;
            animation: thecodes3 3s infinite alternate;
  }
  @-webkit-keyframes thecodes1{
    30%{margin-right:-50px;margin-left:0;}
    100%{margin-right:-10px;margin-left:0;}
  }
  @keyframes thecodes1{
    30%{margin-right:-50px;margin-left:0;}
    100%{margin-right:-10px;margin-left:0;}
  }
  @-webkit-keyframes thecodes2{
    30%{margin-left:0;}
    100%{margin-right:0;}
  }
  @keyframes thecodes2{
    30%{margin-left:0;}
    100%{margin-right:0;}
  }
  @-webkit-keyframes thecodes3{
    30%{margin-left:-50px;}
    100%{margin-left:-10px;}
  }
  @keyframes thecodes3{
    30%{margin-left:-50px;}
    100%{margin-left:-10px;}
  }
  .header-earlier{
    margin-bottom: 90px;
  }
  .header-div{
    background: linear-gradient(270deg, hsla(0, 0%, 97%, 1) 0%, hsla(0, 0%, 99%, 0) 100%);
    background: -webkit-linear-gradient(270deg, hsla(0, 0%, 97%, 1) 0%, hsla(0, 0%, 99%, 0) 100%);
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding: 10px 25px;
  }

  .footer-div{
    background: var(--body-color);
    border-top: 3px solid #334670;
    color: var(--text-color);
    width: 100%;
    padding: 10px 20px;
    margin-top: 20px;
  }
  .footer-div img{
    height: 45px;
    width: auto;
    display: inline-block;
  }
  .footer-div p{
    display: inline-block;
    font-family: 'Quicksand', serif;
    font-size: 13px;
    margin: 13px;
  }
  .footer-div .footer-social{
    float: right;
  }
  .footer-div .footer-social p{
    display: inline-block;
    font-family: 'Quicksand', serif;
    font-size: 13px;
    margin: 13px 7px;
  }
  .footer-div .footer-social p a{
    color: var(--text-color) !important;
    opacity: 0.85;
  }

  .header{
  width: 100%;
  padding-top: 10px;
  background: var(--body-background);
  height: 65px;
  border-bottom: 3px solid #334670;
  color: var(--text-color);
}
.header-left{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-left: 10px;
}
  .network-using{
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: #f7f7f7;
    margin-left: 0;
    border: 3px solid green;
    display: none;
  }
  .network-using img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .thecodes-header{
    height: 46px;
    width: auto;
    margin: 0 5px;
  }
  .header-menu-show{
    height: 46px;
    width: 46px;
    border-radius:6px;
    position: absolute;
    right: 31px;
    cursor: pointer;
    display: none;
    transition: 700ms;
  }
  .header-menu-show:hover{
    background: rgba(0,0,0,0.15);
  }
  .header-menu-show .bi{
    font-size: 30px;
    color: black;
  }
  .header-right{
    display: flex;
    flex-wrap: nowrap;
    text-align: right;
    position: absolute;
    right: 31px;
    transition: 1s;
    margin-top: 3px;
    width: 70%;
  }
  .header-right .header-menu{
    color: white;
    font-size: 15px;
    padding: 5px;
    transition: 700ms;
    margin: 0 auto;
    height: 35px;
    -webkit-transform: translateY(12%);
            transform: translateY(12%);
    margin: 0 5px;
    cursor: pointer;
  }
  .header-right .header-menu:hover{
    font-weight: bold;
    border-radius: 6px;
  }
  .header-right .header-menu .header-link{
    color: white;
  }
  .header-search{
    display: none;
  }
  .search-header{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 7px;
    color: black;    
  }
  
  .search-header input{
    height: 100%;
    width: 100%;
    border: 0;
    background: transparent;
    color: grey;
  }
  .search-header button{
    height: 100%;
    width: 50px;
    background: transparent;
    border: 0;
    color: grey;
  }
  .search-header input::-webkit-input-placeholder {
    color: grey;
    opacity: 0.5;
  }
  .summary-div{
    padding: 0 25px;
  }
  .summary{
    padding: 6px;
    width: 100%;
    border-radius: 6px;
    background: #fff;
    border: 1.5px solid grey;
    transition: 700ms;
    margin-top: 15px;
  }
  .summary:hover{
    border-color: white;
  }
  .title-div{
    width: 100%;
    padding: 6px;
    transition: 700ms;
  }
  .hr-right{
    border-right: 1px solid grey;
  }
  .title-div hr{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .title-div .title-icon{
    font-size: 40px;
    display: inline-block;
    transition: 700ms;
  }
  .title-div .title{
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    position: absolute;
    opacity: 0.5;
    font-size: 13px;
    transition: 700ms;
    cursor: default;
  }
  .title-div .title-value{
    display: inline-block;
    margin-left: 10px;
    margin-top: 20px;
    position: absolute;
    font-size: 25px;
    font-weight: bold;
    transition: 700ms;
    cursor: default;
  }
  .title-div:hover > .title-value{
    color: #5755b9;
    margin-top: 0px;
  }
  .title-div:hover > .title-icon{
    color: rgb(57, 116, 172);
  }
  .title-div:hover > .title{
    margin-top: 35px;
  }
  .hr-mobile{
    display: none;
  }
  .hide-mobile{
    display: block;
  }
  .hide-mobile.inline{
    display: inline-block;
  }
  .content{
    width: 100%;
    border-radius: 5px;
    background: white;
  }
  .text-hash{
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
  }
  .image-validator {
    height: 19px;
    width: auto;
    border-radius: 5px;
    margin-right: 10px;
  }
  .content-table-link{
    color:#174bc4;
  }
  .content-table-link:hover, .content-table-link:focus, .content-table-link:active{
    color: #174bc4;
  }
  .content-table-text{
    font-size: 15px;
    word-wrap: break-word;
  }
  .content-table-title{
    font-weight: bold;
    color: rgb(64, 63, 141);
  }
  .overflow-custom::-webkit-scrollbar {
    width: 4px;
  }
  .overflow-custom::-webkit-scrollbar-track {
    background: #fff; 
  }
  .overflow-custom::-webkit-scrollbar-thumb {
    background: rgba(23, 75, 196, 0.5);
    border-radius:100px;
  }
  .uptime-container{
    display: flex;
    flex-wrap: wrap;
  }
  .uptime-light{
    width: 18px;
    margin: 1.5px;
    text-align: center;
    height: 18px;
    font-size: 30px;
    border-radius:2px;
  }
  .pie-chart{
    margin-top:auto;
    margin-bottom:auto;
    margin-right: auto;
    margin-left: auto;
  }
  .dropdown-earlier{
    margin-top: 0px;
  }
  .dropdown-button::after{
    display: none;
  }
  .dropdown-button-token{
    background: transparent;
    padding: 0;
    border: 0;
    color:black;
    font-size: 15px;
  }
  .dropdown-button-token:hover, .dropdown-button-token:focus{
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .dropdown-menu-token{
    width: inherit !important;
    max-width: 100%;
    z-index: 1;
  }
  .dropdown-item{
    transition: 700ms;
  }
  .switch {
    display: inline-block;
    height: 19px;
    position: absolute;
    width: 45px;
    margin-top: 10px;
  }
  .switch .checkbox {
    display:none;
  }
  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 11px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 11px;
  }
  .checkbox:checked + .slider {
    background-color: #66bb6a;
  }
  .checkbox:checked + .slider:before {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
  }
  .slider.round {
    border-radius: 30px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  .tab-div{
    width: 100%;
    background:transparent;
    display: inline-block;
  }
  .tab-button{
    padding: 15px;
    width: 50%;
    background: white;
    font-weight: bold;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-style: solid;
    border-color: #174bc4;
  }
  .connected-div{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .connected-line {
    height: 3px;
    background: #18bc9c;
    width: 100%;
    margin-top: 21px;
    border-radius: 20px;
  }
  .connected-line:after {
    float: right;
  }
  .connected-div .connected-text{
    width: 160px;
    padding: 6px;
    height: 45px;
    text-align: center;
    border-radius: 50px;
    background: #174bc4;
    font-size: 15px;
    color: white;
    border: 5px solid #f7f7f7;
  }

  .wrap{
    word-wrap: break-word;
  }

 
  
  @media screen and (max-width: 424px) {
    .pie-chart{
      margin-top: 0;
      width: 100px;
      height: auto;
    }
    .footer-social{
      text-align: left;
    }
  }
  
  @media screen and (max-width: 767px) {
    .header-earlier{
      margin-bottom: 135px;
    }
    .header-menu-show{
      display: block;
      background-color: white;
      color: black;
    }
    .header-right{
      display: block;
      width: 60%;
      right: 24px;
      transition: 700ms;
      border-radius: 6px;
      overflow: hidden;
      background-color: white;
      margin-top: 60px;
      height: 0;
      text-align: left;
      z-index: 100;
    }
    .header-menu-show:hover + .header-right{
      height: 180px;
      background-color: whitesmoke;
    }
    .search-header{
      display: none;
    }
    .header-right:hover{
      height: 220px;
    }
    .header-search {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      margin-top: 15px;
    }
    .header-search input{
      width: 100%;
      border-radius: 6px 0 0 6px;
      border: 2px solid #5755b9;
      padding: 6px;
    }
    .header-search button{
      width: 50px;
      border-radius: 0 6px 6px 0;
      border: 2px solid #5755b9;
      padding: 6px;
      background: #5755b9;
      color: grey;
    }
    .hr-right{
      border: 0;
    }
    .hr-mobile{
      display: block;
    }
    .hide-mobile{
      display: none;
    }
    .hide-mobile.inline{
      display: none;
    }

    .footer-div {
      height: 170px;
    }
    .pie-chart{
      margin-top: 0;
      width: 100px;
      height: auto;
    }
  }
  
  .image-size-card{
    font-size: 14px;
    font-weight: bold;
    margin-left: -15px;
  }
  
  .image-size-card2{
    position: right;
  }
  
  .sub_text{
    font-size: 14px;
    font-weight: bold;
  }
  
  .sub_text2{
    font-size: 12px;
    font-weight: bold;
  }
  
  .get-padding{
    margin-top: -50px;
  }
  
  /* Font */
  
  /* Design */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    background-color: #ecf9ff;
  }
  

  body {
    background: var(--body-color);
    font-family: 'Quicksand', serif;
    overflow-x: hidden;
    background-color: white;
  }
  
  .main{
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
  }
  
  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
  
  
  .card-padding{
    padding-right: 50px;
  }
  
  .base-color{
    background-color: #5755B9;
    color: white;
    border-radius: 5px;
    text-align: justify;
    font-size: 14x;
  }
  
  .base-color-button{
    background-color: #5755B9;
    color: white;
    position: center;
  }
  
  .card-detail-image{
    border-radius: 30px;
  }
  
  .box-detail{
    border-radius: 5px;
  }
  
  .my-font{
    font-weight: bold;
    font-size: 16px;
  }

  .arrowup{
    color: red;
  }

  .labeltransaction{
    color: #5755B9;
    font-weight: bold;
  }
